// const server =
//   process.env.NODE_ENV === "development"
//     ? process.env.VUE_APP_SERVER
//     : location.origin;

const server = process.env.VUE_APP_SERVER;

const menu = [
  {
    text: "Início",
    to: "/",
  },
  {
    text: "Quem Somos",
    to: "/quem-somos",
  },
  {
    text: "Documentos",
    to: "/documentos",
  },
  {
    text: "Produtos",
    to: "/produtos",
  },
  {
    text: "Nossos Trabalhos",
    to: "/nossos-trabalhos",
  },
  {
    text: "Fale Conosco",
    to: "/fale-conosco",
  },
];

export { server, menu };
