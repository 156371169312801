export function getYoutubeCode(url) {
  url = new URL(url);

  let code;
  switch (url.host) {
    case "www.youtube.com":
      code = url.searchParams.get("v");
      break;
    case "youtu.be":
      code = url.pathname.replace(/\/(.+)\/?.*/, "$1");
      break;
  }

  return code;
}
