<template>
  <v-chip-group
    v-model="categoriesSelecteds"
    class="categories"
    multiple
    column
  >
    <v-chip
      class="
        secondary--text
        text-caption text-sm-body-1
        font-weight-medium
        rounded
        px-3 px-sm-9
        py-2
        category
      "
      active-class="is-active"
      :value="uri"
      v-for="({ title, uri }, keyCategories) in productsCategories"
      :key="keyCategories"
    >
      {{ title }}
    </v-chip>
  </v-chip-group>
</template>

<script>
import { formatToURL } from "../../utils/formatToURL";

export default {
  computed: {
    query() {
      return this.$route.query;
    },
    categoriesSelecteds: {
      get() {
        return this.query.categoria
          ? this.query.categoria.split(",").map((v) => v)
          : [];
      },
      set(v) {
        const query = { ...this.query };
        if (v) query.categoria = v.join(",");
        else delete query.categoria;

        this.$router.push({ query });
      },
    },
    productsCategories() {
      return this.$rest("productsCategories").list.map(({ id, title }) => ({
        title,
        uri: `${formatToURL(title)}-${id}`,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.categories::v-deep {
  .v-slide-group__content {
    display: flex;
    justify-content: center;
  }
}

.category {
  background-color: white !important;
  border: 1px solid var(--v-secondary-lighten2) !important;

  &.is-active {
    background-color: var(--v-secondary-lighten2) !important;
  }
}
</style>
