import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        secondary: {
          lighten5: "#F5F5F5",
          lighten3: "#EDEDED",
          lighten2: "#B9B7BD",
          lighten1: "#87868A",
          base: "#363334",
          darken1: "#535257",
        },
        primary: "#A92323",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
  icons: {
    iconfont: "fa",
  },
});
