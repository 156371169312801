<template>
  <div>
    <v-container class="py-10 py-md-13 py-lg-16">
      <div class="d-flex align-md-center flex-column flex-md-row">
        <TitleWidget :align="mobile ? 'center' : 'left'">PRODUTOS</TitleWidget>

        <v-spacer />

        <ButtonWidget
          v-if="informations.catalog"
          :align="mobile ? 'center' : 'left'"
          class="mt-4 mt-md-0 mr-md-2"
          :href="files + informations.catalog"
          target="_blank"
        >
          <v-icon left> fa-file-pdf </v-icon>
          Baixar Catálogo
        </ButtonWidget>

        <ProductsSearchForm class="mt-4 mt-md-0" />
      </div>

      <ProductsCategoriesChipsWidget class="mt-3 mt-md-7" />

      <LoadingWidget v-if="loading" />

      <transition name="fade">
        <div v-if="!loading">
          <div v-if="items.length" class="mt-3 mt-sm-6 grid-list">
            <template v-for="(item, keyItems) in items">
              <ProductCard :key="keyItems" v-bind="item" />
            </template>
          </div>

          <v-alert v-else class="mt-10" type="info">
            Nenhum Produto encontrado.
          </v-alert>

          <v-pagination
            v-if="pagesLength > 1"
            class="mt-10"
            color="primary"
            v-model="page"
            :length="pagesLength"
          ></v-pagination>
        </div>
      </transition>
    </v-container>
  </div>
</template>

<script>
import TitleWidget from "../components/widgets/TitleWidget.vue";
import ButtonWidget from "../components/widgets/ButtonWidget.vue";
import ProductsSearchForm from "../components/forms/ProductsSearchForm.vue";
import ProductsCategoriesChipsWidget from "../components/widgets/ProductsCategoriesChipsWidget.vue";
import LoadingWidget from "../components/widgets/LoadingWidget.vue";
import ProductCard from "../components/cards/ProductCard.vue";
import { formatToURL } from "../utils/formatToURL";

const _config = {
  mod: "products",
  path: "/produtos",
  itemsPerPage: 24,
  returnTotalItems: 1,
};

export default {
  data: () => ({
    loading: true,
    items: [],
    path: _config.path,
    itemsPerPage: _config.itemsPerPage,
    returnTotalItems: _config.returnTotalItems,
    totalItems: 0,
  }),
  created() {
    this.getList();
  },
  computed: {
    page: {
      get() {
        const p = this.$route.query.p;
        return p ? parseInt(p) : 1;
      },
      set(v) {
        const query = { ...this.$route.query };
        query.p = v;

        return this.$router.push({ query });
      },
    },
    pagesLength() {
      return Math.ceil(this.totalItems / _config.itemsPerPage);
    },
    filters() {
      return this.$route.query;
    },
    categoriesSelecteds() {
      return this.filters.categoria
        ? this.filters.categoria.split(",").map((v) => v)
        : [];
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    informations() {
      return this.$rest("informations").item;
    },
  },
  methods: {
    getList() {
      this.loading = true;

      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        returnTotalItems: this.returnTotalItems,
      };

      if (this.categoriesSelecteds.length)
        params.categories = this.categoriesSelecteds.map((item) => {
          return Number(item.replace(/^.*-([0-9]+)$/, "$1"));
        });

      if (this.filters.q) params.search = this.filters.q;

      this.$rest(_config.mod)
        .get({ params })
        .then((data) => {
          const items = data?.list || data;
          this.totalItems = data?.totalItems;

          this.items = items.map(({ id, img, title }) => ({
            img,
            title,
            to: `${this.path}/${formatToURL(title)}-${id}`,
          }));
        })
        .then(() => (this.loading = false));
    },
  },
  watch: {
    $route() {
      this.getList();
    },
  },
  components: {
    TitleWidget,
    ButtonWidget,
    ProductsSearchForm,
    ProductsCategoriesChipsWidget,
    LoadingWidget,
    ProductCard,
  },
};
</script>

<style lang="scss" scoped>
.grid-list {
  display: grid;
  gap: 40px 30px;
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: 1264px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 600px) {
    gap: 16px;
  }
}
</style>
