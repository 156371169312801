<template>
  <v-card
    v-hover="['span', 'primary--text']"
    class="overflow-hidden item"
    :color="mobile ? 'transparent' : 'white'"
    :to="to"
    rounded="xl"
    flat
  >
    <img
      class="d-block rounded-xl item-img"
      :src="files + img + '?resize=1&w=200'"
      :alt="title"
      width="100%"
    />

    <div class="py-2 py-sm-4 py-lg-8 px-3 px-lg-5">
      <div
        class="
          black--text
          text-subtitle-1 text-lg-h6 text-center text-sm-left
          font-weight-bold
        "
      >
        {{ title }}
      </div>

      <div
        v-if="!mobile"
        class="black--text text-caption text-lg-body-1 mt-1 item-btn"
      >
        <span>Ver mais</span>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: grid;
  gap: 0;
  grid-template-columns: 165px auto;

  @media screen and (max-width: 1264px) {
    grid-template-columns: 120px auto;
  }

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }

  &-img {
    aspect-ratio: 1 / 1;
    background-color: #ffffff;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: top left;
    object-position: top left;
  }

  &-btn {
    transition: all 0.1s linear;
  }
}
</style>
