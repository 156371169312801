<template>
  <div :class="['d-flex', alignClass]">
    <v-btn
      v-bind="$attrs"
      v-on="$listeners"
      :class="[
        'px-10 font-weight-regular text-none text-body-1',
        secondary && 'grey--text text--darken-4',
      ]"
      :color="!secondary ? 'primary' : 'secondary'"
      height="48px"
      depressed
      rounded
    >
      <slot />
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    align: {
      type: String,
      default: "left",
      validator: (val) => ["left", "center", "right"].includes(val),
    },
    secondary: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    alignClass() {
      const classes = {
        left: "justify-start",
        center: "justify-center",
        right: "justify-end",
      };

      return classes[this.align];
    },
  },
};
</script>
