var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.images.length)?_c('div',[(_vm.featuredImage || _vm.images[0].src)?_c('img',{class:[
      'd-block cursor-pointer item-img',
      _vm.mobile ? 'rounded-sm' : 'rounded-xl' ],attrs:{"src":_vm.featuredImage || _vm.images[0].src,"width":"100%"},on:{"click":function($event){_vm.index = _vm.featuredIndex || 0}}}):_vm._e(),(_vm.images.length > 1)?_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"position-relative"},[_c('div',{staticClass:"px-4"},[_c('hooper-container',{ref:"carouselImages",staticClass:"property-slides-images",staticStyle:{"height":"auto"},attrs:{"settings":_vm.hooperSettings,"mouseDrag":_vm.images.length > 1,"touchDrag":_vm.images.length > 1},on:{"slide":_vm.updateCarousel}},_vm._l((_vm.images),function(item,keyImages){return _c('hooper-slide',{key:keyImages,staticClass:"px-2 item"},[_c('div',{staticClass:"position-relative slides-img",class:{
                'slides-img-featured':
                  item.src === _vm.featuredImage ||
                  (!_vm.featuredImage && keyImages === 0),
              }},[_c('img',{class:[
                  'd-block cursor-pointer',
                  _vm.mobile ? 'rounded-sm' : 'rounded-lg' ],attrs:{"src":item.thumbnail,"width":"100%"},on:{"click":function($event){_vm.featuredImage = item.src;
                  _vm.featuredIndex = keyImages;
                  _vm.slideTo(keyImages);}}})])])}),1)],1),(_vm.images.length > 1)?_c('div',{staticClass:"d-flex justify-space-between slides-nav"},[_c('a',{staticClass:"\n            d-flex\n            align-center\n            justify-center\n            elevation-2\n            cursor-pointer\n            slides-nav-item\n          ",on:{"click":function($event){$event.preventDefault();return _vm.slidePrev.apply(null, arguments)}}},[_c('v-icon',{attrs:{"color":"primary","size":18}},[_vm._v(" fa-angle-left ")])],1),_c('a',{staticClass:"\n            d-flex\n            align-center\n            justify-center\n            elevation-2\n            cursor-pointer\n            slides-nav-item\n          ",on:{"click":function($event){$event.preventDefault();return _vm.slideNext.apply(null, arguments)}}},[_c('v-icon',{attrs:{"color":"primary","size":18}},[_vm._v(" fa-angle-right ")])],1)]):_vm._e()])]):_vm._e(),_c('Tinybox',{attrs:{"images":_vm.images},model:{value:(_vm.index),callback:function ($$v) {_vm.index=$$v},expression:"index"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }