<template>
  <v-app-bar
    class="header"
    hide-on-scroll
    elevate-on-scroll
    scroll-threshold="100"
    height="126px"
    app
  >
    <v-sheet color="#f7f7f7" width="100%">
      <v-container class="py-0">
        <v-sheet
          class="d-flex align-center justify-space-between"
          color="transparent"
          height="40px"
          width="100%"
        >
          <div class="d-flex align-center">
            <v-btn
              v-if="informations.address"
              class="text-caption text-none"
              color="secondary lighten-1"
              :href="informations.google_maps"
              target="_blank"
              text
            >
              <v-icon size="16" left> fa-map-marker-alt </v-icon>

              {{ informations.address }}
            </v-btn>

            <v-sheet
              v-if="informations.address && tel.length"
              height="15px"
              width="1px"
              color="rgba(0,0,0,.2)"
            ></v-sheet>

            <template v-for="({ text, link, icon }, keyTel) in tel">
              <v-icon
                :key="keyTel"
                v-if="keyTel === 0 && tel.length > 1"
                class="ml-4"
                size="16"
                left
              >
                {{ icon }}
              </v-icon>

              <v-sheet
                :key="keyTel"
                v-else-if="keyTel > 0"
                height="15px"
                width="1px"
                color="rgba(0,0,0,.2)"
              ></v-sheet>

              <v-btn
                :key="keyTel"
                v-if="text"
                class="text-caption text-none"
                color="secondary lighten-1"
                :href="link"
                text
              >
                <v-icon v-if="keyTel === 0 && tel.length === 1" size="16" left>
                  {{ icon }}
                </v-icon>

                {{ text }}
              </v-btn>
            </template>
          </div>

          <div class="d-flex align-center">
            <template v-for="({ text, link, icon }, keySocial) in social">
              <v-btn
                :key="keySocial"
                v-if="link"
                class="ml-2 social-btn"
                :href="link"
                target="_blank"
                :title="text"
                height="30px"
                width="30px"
                depressed
                fab
              >
                <v-icon size="16">
                  {{ icon }}
                </v-icon>
              </v-btn>
            </template>
          </div>
        </v-sheet>
      </v-container>
    </v-sheet>

    <v-sheet
      class="d-flex align-center"
      color="primary"
      width="100%"
      height="100%"
    >
      <v-container class="d-flex align-center py-0">
        <router-link to="/">
          <img
            v-if="informations.img"
            :src="files + informations.img + '?resize=1&h=48'"
            :alt="informations.name"
            class="d-block"
            height="48px"
          />
        </router-link>

        <v-spacer />

        <div class="d-flex align-center">
          <div
            v-for="({ text, to, dropdown }, keyMenu) in menu"
            :key="keyMenu"
            class="d-flex align-center"
          >
            <v-sheet
              v-if="keyMenu > 0"
              class="mx-1 mx-lg-3"
              color="rgba(255, 255, 255, 0.2)"
              height="15px"
              width="1px"
            ></v-sheet>

            <v-btn
              v-if="!dropdown"
              class="
                white--text
                text-caption text-lg-body-1 text-uppercase
                font-weight-medium
                px-2 px-lg-3
                py-5
                nav-link
              "
              :to="to"
              tile
              depressed
              text
            >
              {{ text }}
            </v-btn>

            <v-menu v-else offset-y tile>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="
                    white--text
                    text-caption text-lg-body-1 text-uppercase
                    font-weight-medium
                    px-2 px-lg-3
                    py-5
                    nav-link
                  "
                  tile
                  depressed
                  text
                >
                  {{ text }}

                  <v-icon class="ml-3" size="12"> fa-caret-down </v-icon>
                </v-btn>
              </template>
              <v-list tile>
                <v-list-item
                  v-for="(item, keyDropdown) in dropdown"
                  :key="keyDropdown"
                  :to="item.to"
                >
                  <v-list-item-title class="grey--text text--darken-3">
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-container>
    </v-sheet>
  </v-app-bar>
</template>

<script>
import { getTelLinkFromPhone } from "../../../utils/getTelLinkFromPhone";
import { getSocial } from "../../../utils/getSocial";

export default {
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    social() {
      return getSocial(this.informations);
    },
    tel() {
      const contact = [];
      const tel = this.informations.tel;

      if (tel) {
        let telSplit = tel.split(";");

        if (Array.isArray(telSplit) && telSplit.length > 1) {
          telSplit.forEach(function (val) {
            contact.push({
              name: "Telefone",
              text: val,
              link: getTelLinkFromPhone(val),
              icon: "fa-phone-alt",
            });
          });
        } else {
          contact.push({
            name: "Telefone",
            text: tel,
            link: getTelLinkFromPhone(tel),
            icon: "fa-phone-alt",
          });
        }
      }

      return contact;
    },
  },
};
</script>

<style lang="scss" scoped>
.header::v-deep {
  .v-toolbar__content {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
}

.nav-link {
  &:before {
    opacity: 0 !important;
  }
}

.social-btn {
  background-color: transparent !important;
  border: 1px solid var(--v-secondary-darken1) !important;

  i {
    color: var(--v-secondary-darken1) !important;
  }

  &:hover {
    background-color: var(--v-primary-base) !important;
    border: 1px solid var(--v-primary-base) !important;

    i {
      color: white !important;
    }
  }
}
</style>
