import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import DocumentsView from "../views/DocumentsView.vue";
import ProductsListView from "../views/ProductsListView.vue";
import ProductsItemView from "../views/ProductsItemView.vue";
import JobsListView from "../views/JobsListView.vue";
import Error404View from "../views/Error404View.vue";
import PolicyPrivacyView from "../views/PolicyPrivacyView.vue";
import ContactView from "../views/ContactView.vue";
import ContactResponseView from "../views/ContactResponseView.vue";
// import UnderConstructionView from "../views/UnderConstructionView.vue";

Vue.use(VueRouter);

const routes = [
  {
    name: "Início",
    path: "/",
    component: HomeView,
  },
  {
    name: "Quem Somos",
    path: "/quem-somos",
    component: AboutView,
  },
  {
    name: "Documentos",
    path: "/documentos",
    component: DocumentsView,
  },
  {
    name: "Produtos",
    path: "/produtos",
    component: ProductsListView,
  },
  {
    name: "Produto",
    path: "/produtos/:item",
    component: ProductsItemView,
  },
  {
    name: "Nossos Trabalhos",
    path: "/nossos-trabalhos",
    component: JobsListView,
  },
  {
    name: "Fale Conosco",
    path: "/fale-conosco",
    component: ContactView,
  },
  {
    name: "Obrigado!",
    path: "/fale-conosco/obrigado",
    component: ContactResponseView,
  },
  {
    name: "Política de Privacidade",
    path: "/politica-de-privacidade",
    component: PolicyPrivacyView,
  },
  {
    path: "/erro404",
    name: "Erro 404",
    component: Error404View,
  },
  {
    path: "*",
    name: "Erro 404",
    component: Error404View,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

export default router;
