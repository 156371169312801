<template>
  <footer class="shell-footer">
    <v-container class="py-6">
      <div class="grid-list">
        <div v-if="!mobile" class="grid-list-item">
          <div class="d-flex justify-center justify-lg-start">
            <router-link to="/">
              <img
                v-if="informations.img"
                :src="files + informations.img"
                height="64px"
                class="d-block"
              />
            </router-link>
          </div>
        </div>

        <div class="d-flex flex-column align-start grid-list-item">
          <div class="white--text text-h5 font-weight-medium ml-3">
            <span class="font-secondary"> CONTATO </span>
          </div>

          <div v-for="(item, keyContact) in contacts" :key="keyContact">
            <v-btn
              v-if="!Array.isArray(item)"
              class="white--text text-body-2 text-none btn-contact"
              height="auto"
              min-height="36px"
              :href="item.link"
              :target="item.target"
              :title="item.name"
              depressed
              color="transparent"
            >
              <v-icon v-if="item.icon" :size="item.size" class="mr-2">
                {{ item.icon }}
              </v-icon>

              <span v-html="item.text"></span>
            </v-btn>

            <div v-else class="d-flex flex-column align-start">
              <v-btn
                v-for="(
                  { name, text, link, target, icon, size }, keyContactItem
                ) in item"
                :key="keyContactItem"
                class="white--text text-body-2 text-none btn-contact"
                height="auto"
                min-height="36px"
                :href="link"
                :target="target"
                :title="name"
                depressed
                color="transparent"
              >
                <v-icon v-if="icon" :size="size" class="mr-2">
                  {{ icon }}
                </v-icon>

                <span v-html="text"></span>
              </v-btn>
            </div>
          </div>

          <div class="d-flex flex-column align-start">
            <v-btn
              class="white--text text-body-2 text-none btn-contact"
              height="auto"
              min-height="36px"
              to="/politica-de-privacidade"
              color="transparent"
              depressed
            >
              <v-icon :size="18" class="mr-2"> fa-shield-alt </v-icon>

              Política de Privacidade
            </v-btn>
          </div>
        </div>

        <div class="d-flex flex-column align-start grid-list-item">
          <div class="white--text text-h5 font-weight-medium ml-3">
            <span class="font-secondary"> PRODUTOS </span>
          </div>

          <div class="grid-list-categories">
            <template
              v-for="({ title, to }, keyCategories) in productsCategories"
            >
              <v-btn
                :key="keyCategories"
                :class="[
                  'justify-start text-body-2 text-none',
                  $route.path === '/produtos' && $route.query.categoria === to
                    ? 'white--text'
                    : 'secondary--text text--lighten-2',
                ]"
                color="transparent"
                :to="'/produtos?categoria=' + to"
                min-height="36px"
                height="auto"
                :title="title"
                exact
                depressed
              >
                <v-icon left color="primary" size="12">
                  fa-chevron-right
                </v-icon>

                {{ title }}
              </v-btn>
            </template>
          </div>
        </div>

        <div v-if="mobile" class="grid-list-item">
          <div class="d-flex justify-center justify-lg-start">
            <router-link to="/">
              <img
                v-if="informations.img"
                :src="files + informations.img"
                height="64px"
                class="d-block"
              />
            </router-link>
          </div>
        </div>

        <div class="grid-list-item">
          <div class="d-flex justify-center justify-lg-end align-center">
            <div
              v-for="({ text, link, icon, size }, keySocial) in social"
              :key="keySocial"
              class="d-inline-flex"
            >
              <v-btn
                class="ml-3 btn-social"
                :href="link"
                target="_blank"
                min-width="auto"
                :title="text"
                depressed
                rounded
                width="46px"
                height="46px"
                fab
              >
                <v-icon :size="size">{{ icon }}</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-container>

    <v-sheet color="primary">
      <v-container
        class="
          pt-0
          pb-5
          d-flex
          flex-column flex-sm-row
          justify-space-between
          align-center
          text-center
        "
      >
        <div class="white--text text-body-1 mt-5">
          <b class="font-weight-bold">{{ informations.name }}</b>
          © {{ year }}.
          <span class="d-block d-sm-inline-block">
            Todos os direitos reservados.
          </span>
        </div>
        <a
          class="d-flex mt-5"
          href="https://www.mrxweb.com.br/"
          target="_blank"
        >
          <img
            class="shell-footer-logo"
            src="../../assets/img/mrxweb.svg"
            alt="MRX Web Sites, Publicidade, Marketing Digital, Produção de Vídeos - Sinop/MT"
            title="MRX Web Sites, Publicidade, Marketing Digital, Produção de Vídeos - Sinop/MT"
            height="20px"
          />
        </a>
      </v-container>
    </v-sheet>
  </footer>
</template>

<script>
import { getContact } from "../../utils/getContact";
import { getSocial } from "../../utils/getSocial";
import { formatToURL } from "../../utils/formatToURL";

export default {
  data: () => ({
    year: new Date().getFullYear(),
  }),
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    contacts() {
      return getContact(this.informations);
    },
    social() {
      return getSocial(this.informations);
    },
    productsCategories() {
      return this.$rest("productsCategories").list.map(({ id, title }) => ({
        title,
        to: `${formatToURL(title)}-${id}`,
      }));
    },
    mobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>

<style lang="scss" scoped>
.shell-footer {
  background-color: var(--v-secondary-base);
  background-image: url(../../assets/img/bg-footer.png);
  background-position: center;
  background-repeat: repeat;

  .grid-list {
    display: grid;
    gap: 24px 64px;
    grid-template-columns: auto 300px auto auto;
    justify-content: space-between;

    @media screen and (min-width: 600.001px) and (max-width: 1264px) {
      gap: 24px;
      grid-template-columns: auto auto;
      justify-content: center;

      &-item:first-child,
      &-item:last-child {
        grid-column: 1 / -1;
      }
    }

    @media screen and (max-width: 600px) {
      grid-template-columns: minmax(0, 360px);
      justify-content: center;
    }

    .btn-contact::v-deep {
      .v-btn__content {
        max-width: 100%;
        white-space: normal;
      }
    }
  }
}

.grid-list-categories::v-deep {
  display: grid;
  gap: 0 8px;
  grid-template-columns: auto auto;

  .v-btn {
    &:before {
      opacity: 0 !important;
    }
  }
}

.btn-social {
  background-color: transparent !important;
  border: 1px solid #ffffff !important;
  color: #ffffff !important;
  transition: all 0.1s linear;

  &:hover {
    background-color: var(--v-primary-base) !important;
    border: 1px solid var(--v-primary-base) !important;
  }
}
</style>
