var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"shell-footer"},[_c('v-container',{staticClass:"py-6"},[_c('div',{staticClass:"grid-list"},[(!_vm.mobile)?_c('div',{staticClass:"grid-list-item"},[_c('div',{staticClass:"d-flex justify-center justify-lg-start"},[_c('router-link',{attrs:{"to":"/"}},[(_vm.informations.img)?_c('img',{staticClass:"d-block",attrs:{"src":_vm.files + _vm.informations.img,"height":"64px"}}):_vm._e()])],1)]):_vm._e(),_c('div',{staticClass:"d-flex flex-column align-start grid-list-item"},[_c('div',{staticClass:"white--text text-h5 font-weight-medium ml-3"},[_c('span',{staticClass:"font-secondary"},[_vm._v(" CONTATO ")])]),_vm._l((_vm.contacts),function(item,keyContact){return _c('div',{key:keyContact},[(!Array.isArray(item))?_c('v-btn',{staticClass:"white--text text-body-2 text-none btn-contact",attrs:{"height":"auto","min-height":"36px","href":item.link,"target":item.target,"title":item.name,"depressed":"","color":"transparent"}},[(item.icon)?_c('v-icon',{staticClass:"mr-2",attrs:{"size":item.size}},[_vm._v(" "+_vm._s(item.icon)+" ")]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(item.text)}})],1):_c('div',{staticClass:"d-flex flex-column align-start"},_vm._l((item),function(ref,keyContactItem){
var name = ref.name;
var text = ref.text;
var link = ref.link;
var target = ref.target;
var icon = ref.icon;
var size = ref.size;
return _c('v-btn',{key:keyContactItem,staticClass:"white--text text-body-2 text-none btn-contact",attrs:{"height":"auto","min-height":"36px","href":link,"target":target,"title":name,"depressed":"","color":"transparent"}},[(icon)?_c('v-icon',{staticClass:"mr-2",attrs:{"size":size}},[_vm._v(" "+_vm._s(icon)+" ")]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(text)}})],1)}),1)],1)}),_c('div',{staticClass:"d-flex flex-column align-start"},[_c('v-btn',{staticClass:"white--text text-body-2 text-none btn-contact",attrs:{"height":"auto","min-height":"36px","to":"/politica-de-privacidade","color":"transparent","depressed":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":18}},[_vm._v(" fa-shield-alt ")]),_vm._v(" Política de Privacidade ")],1)],1)],2),_c('div',{staticClass:"d-flex flex-column align-start grid-list-item"},[_c('div',{staticClass:"white--text text-h5 font-weight-medium ml-3"},[_c('span',{staticClass:"font-secondary"},[_vm._v(" PRODUTOS ")])]),_c('div',{staticClass:"grid-list-categories"},[_vm._l((_vm.productsCategories),function(ref,keyCategories){
var title = ref.title;
var to = ref.to;
return [_c('v-btn',{key:keyCategories,class:[
                'justify-start text-body-2 text-none',
                _vm.$route.path === '/produtos' && _vm.$route.query.categoria === to
                  ? 'white--text'
                  : 'secondary--text text--lighten-2' ],attrs:{"color":"transparent","to":'/produtos?categoria=' + to,"min-height":"36px","height":"auto","title":title,"exact":"","depressed":""}},[_c('v-icon',{attrs:{"left":"","color":"primary","size":"12"}},[_vm._v(" fa-chevron-right ")]),_vm._v(" "+_vm._s(title)+" ")],1)]})],2)]),(_vm.mobile)?_c('div',{staticClass:"grid-list-item"},[_c('div',{staticClass:"d-flex justify-center justify-lg-start"},[_c('router-link',{attrs:{"to":"/"}},[(_vm.informations.img)?_c('img',{staticClass:"d-block",attrs:{"src":_vm.files + _vm.informations.img,"height":"64px"}}):_vm._e()])],1)]):_vm._e(),_c('div',{staticClass:"grid-list-item"},[_c('div',{staticClass:"d-flex justify-center justify-lg-end align-center"},_vm._l((_vm.social),function(ref,keySocial){
              var text = ref.text;
              var link = ref.link;
              var icon = ref.icon;
              var size = ref.size;
return _c('div',{key:keySocial,staticClass:"d-inline-flex"},[_c('v-btn',{staticClass:"ml-3 btn-social",attrs:{"href":link,"target":"_blank","min-width":"auto","title":text,"depressed":"","rounded":"","width":"46px","height":"46px","fab":""}},[_c('v-icon',{attrs:{"size":size}},[_vm._v(_vm._s(icon))])],1)],1)}),0)])])]),_c('v-sheet',{attrs:{"color":"primary"}},[_c('v-container',{staticClass:"\n        pt-0\n        pb-5\n        d-flex\n        flex-column flex-sm-row\n        justify-space-between\n        align-center\n        text-center\n      "},[_c('div',{staticClass:"white--text text-body-1 mt-5"},[_c('b',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.informations.name))]),_vm._v(" © "+_vm._s(_vm.year)+". "),_c('span',{staticClass:"d-block d-sm-inline-block"},[_vm._v(" Todos os direitos reservados. ")])]),_c('a',{staticClass:"d-flex mt-5",attrs:{"href":"https://www.mrxweb.com.br/","target":"_blank"}},[_c('img',{staticClass:"shell-footer-logo",attrs:{"src":require("../../assets/img/mrxweb.svg"),"alt":"MRX Web Sites, Publicidade, Marketing Digital, Produção de Vídeos - Sinop/MT","title":"MRX Web Sites, Publicidade, Marketing Digital, Produção de Vídeos - Sinop/MT","height":"20px"}})])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }