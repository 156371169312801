import { render, staticRenderFns } from "./ProductsCategoriesChipsWidget.vue?vue&type=template&id=2e2244d8&scoped=true&"
import script from "./ProductsCategoriesChipsWidget.vue?vue&type=script&lang=js&"
export * from "./ProductsCategoriesChipsWidget.vue?vue&type=script&lang=js&"
import style0 from "./ProductsCategoriesChipsWidget.vue?vue&type=style&index=0&id=2e2244d8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e2244d8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
installComponents(component, {VChip,VChipGroup})
