<template>
  <div>
    <v-container class="py-10 py-md-13 py-lg-16">
      <TitleWidget align="center">nossos trabalhos</TitleWidget>

      <LoadingWidget v-if="loading" />

      <transition name="fade">
        <div v-if="!loading">
          <div v-if="items.length" class="mt-6 grid-list">
            <template v-for="(item, keyItems) in items">
              <div
                :key="keyItems"
                @click="
                  gallery = item.gallery;
                  index = 0;
                "
                v-ripple
                class="cursor-pointer"
              >
                <JobCard v-bind="item" />
              </div>
            </template>
          </div>

          <v-alert v-else class="mt-10" type="info">
            Nenhum Produto encontrado.
          </v-alert>

          <v-pagination
            v-if="pagesLength > 1"
            class="mt-10"
            color="primary"
            v-model="page"
            :length="pagesLength"
          ></v-pagination>
        </div>
      </transition>
    </v-container>

    <Tinybox :images="gallery" v-model="index" />
  </div>
</template>

<script>
import TitleWidget from "../components/widgets/TitleWidget.vue";
import LoadingWidget from "../components/widgets/LoadingWidget.vue";
import JobCard from "../components/cards/JobCard.vue";
import Tinybox from "vue-tinybox";

const _config = {
  mod: "jobs",
  itemsPerPage: 12,
  returnTotalItems: 1,
};

export default {
  data: () => ({
    loading: true,
    items: [],
    index: null,
    gallery: [],
    totalItems: 0,
  }),
  created() {
    this.getList();
  },
  computed: {
    page: {
      get() {
        const p = this.$route.query.p;
        return p ? parseInt(p) : 1;
      },
      set(v) {
        const query = { ...this.$route.query };
        query.p = v;

        return this.$router.push({ query });
      },
    },
    pagesLength() {
      return Math.ceil(this.totalItems / _config.itemsPerPage);
    },
  },
  methods: {
    getList() {
      const params = {
        page: this.page,
        itemsPerPage: _config.itemsPerPage,
        returnTotalItems: _config.returnTotalItems,
      };

      this.$rest(_config.mod)
        .get({ params })
        .then((data) => {
          const items = data?.list || data;
          this.totalItems = data?.totalItems;

          this.items = items.map(({ img, title }) => ({
            img: img?.featured,
            title,
            gallery: [
              ...(img?.featured
                ? [
                    {
                      src: this.files + img.featured + "?resize=1&w=1280",
                      thumbnail: this.files + img.featured + "?resize=1&w=200",
                    },
                  ]
                : []),
              ...img?.list.map(({ path }) => {
                return {
                  src: this.files + path + "?resize=1&w=1280",
                  thumbnail: this.files + path + "?resize=1&w=200",
                };
              }),
            ],
          }));
        })
        .then(() => (this.loading = false));
    },
  },
  watch: {
    $route() {
      this.getList();
    },
  },
  components: {
    TitleWidget,
    LoadingWidget,
    JobCard,
    Tinybox,
  },
};
</script>

<style lang="scss" scoped>
.grid-list {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 600px) {
    gap: 12px;
    grid-template-columns: 1fr;
  }
}
</style>
