<template>
  <v-card
    v-hover="['span', 'primary--text']"
    class="overflow-hidden item"
    color="#F5F5F5"
    :rounded="mobile ? 'lg' : 'xl'"
    :to="to"
    flat
  >
    <img
      :class="['d-block item-img', mobile ? 'rounded-lg' : 'rounded-xl']"
      :src="files + img + '?resize=1&w=400'"
      :alt="title"
      width="100%"
    />

    <div
      class="
        secondary--text
        text-body-1 text-md-h6 text-center
        font-weight-bold
        py-3 py-sm-6
        px-1 px-sm-3
        item-title
      "
      :class="{ 'item-title-ellipsis': limitTitle }"
    >
      <span>{{ title }}</span>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
    limitTitle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  &-img {
    aspect-ratio: 16 / 9;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
  }

  &-title {
    transition: all 0.1s linear;

    &.item-title-ellipsis {
      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
