<template>
  <v-card
    class="file-card d-flex justify-space-between align-center px-4 py-2"
    outlined
  >
    <div class="font-weight-bold grey--text text--darken-2">{{ title }}</div>
    <v-btn
      :href="files + file"
      :download="title"
      :text="!xs"
      :icon="xs"
      class="text-none"
      target="_blank"
    >
      <v-icon :left="!xs" small> fas fa-download </v-icon>
      <span v-if="!xs"> Baixar </span>
    </v-btn>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    file: {
      type: String,
      required: true,
    },
  },
  computed: {
    xs() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>
