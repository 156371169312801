<template>
  <div>
    <v-container class="py-10 py-md-13 py-lg-16">
      <TitleWidget align="center">DOCUMENTOS</TitleWidget>

      <LoadingWidget v-if="loading" />

      <transition name="fade">
        <div v-if="!loading">
          <div v-if="items.length" class="mt-3 mt-sm-6 grid-list">
            <template v-for="(item, keyItems) in items">
              <DocumentCard :key="keyItems" v-bind="item" />
            </template>
          </div>

          <v-alert v-else class="mt-10" type="info">
            Nenhum Produto encontrado.
          </v-alert>

          <v-pagination
            v-if="pagesLength > 1"
            class="mt-10"
            color="primary"
            v-model="page"
            :length="pagesLength"
          ></v-pagination>
        </div>
      </transition>
    </v-container>
  </div>
</template>

<script>
import TitleWidget from "../components/widgets/TitleWidget.vue";
import LoadingWidget from "../components/widgets/LoadingWidget.vue";
import DocumentCard from "../components/cards/DocumentCard.vue";

const _config = {
  mod: "documents",
  path: "/documentos",
  itemsPerPage: 1000,
  returnTotalItems: 1,
};

export default {
  data: () => ({
    loading: true,
    items: [],
    path: _config.path,
    itemsPerPage: _config.itemsPerPage,
    returnTotalItems: _config.returnTotalItems,
    totalItems: 0,
  }),
  created() {
    this.getList();
  },
  computed: {
    page: {
      get() {
        const p = this.$route.query.p;
        return p ? parseInt(p) : 1;
      },
      set(v) {
        const query = { ...this.$route.query };
        query.p = v;

        return this.$router.push({ query });
      },
    },
    pagesLength() {
      return Math.ceil(this.totalItems / _config.itemsPerPage);
    },
  },
  methods: {
    getList() {
      this.loading = true;

      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        returnTotalItems: this.returnTotalItems,
      };

      this.$rest(_config.mod)
        .get({ params })
        .then((data) => {
          const items = data?.list || data;
          this.totalItems = data?.totalItems;

          this.items = items.map(({ file, title }) => ({
            file,
            title,
          }));
        })
        .then(() => (this.loading = false));
    },
  },
  watch: {
    $route() {
      this.getList();
    },
  },
  components: {
    TitleWidget,
    LoadingWidget,
    DocumentCard,
  },
};
</script>

<style lang="scss" scoped>
.grid-list {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: 600px) {
    gap: 16px;
    grid-template-columns: 1fr;
  }
}
</style>
