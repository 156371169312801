<template>
  <div>
    <LoadingWidget v-if="loading" />

    <transition name="fade">
      <v-container
        v-if="!loading && item.title"
        class="py-10 py-md-13 py-lg-16 item"
      >
        <div class="d-flex flex-column flex-sm-row align-center">
          <TitleWidget :align="mobile ? 'center' : 'left'">
            {{ item.title }}
          </TitleWidget>

          <v-spacer v-if="!mobile" />

          <v-btn
            @click="share"
            class="ml-md-4 text-none"
            :class="{ 'mt-4': mobile }"
            color="primary"
            depressed
            outlined
            rounded
          >
            <v-icon color="primary" size="16px" left> fa fa-share-alt </v-icon>
            Compartilhar
          </v-btn>
        </div>

        <div class="grid-list">
          <div class="mt-4 mt-sm-10 item-gallery">
            <ProductImagesWidget :images="images" />
          </div>

          <div class="mt-4 mt-sm-10">
            <div
              v-if="item.text"
              class="secondary--text text--lighten-1 text-body-1 ck-content"
              v-html="item.text"
            ></div>

            <ButtonWidget
              v-if="informations.whatsapp_float"
              class="mt-6"
              :href="informations.whatsapp_float"
              target="_blank"
              block
            >
              Solicitar Orçamento
            </ButtonWidget>
          </div>
        </div>

        <RelatedProductsWidget
          class="mt-10 mt-md-13 mt-lg-16"
          :id="itemId"
          :category="item.category.id"
        />
      </v-container>
    </transition>
  </div>
</template>

<script>
import LoadingWidget from "../components/widgets/LoadingWidget.vue";
import TitleWidget from "../components/widgets/TitleWidget.vue";
import ProductImagesWidget from "../components/widgets/ProductImagesWidget.vue";
import ButtonWidget from "../components/widgets/ButtonWidget.vue";
import RelatedProductsWidget from "../components/widgets/RelatedProductsWidget.vue";

const _config = {
  mod: "products",
};

export default {
  data: () => ({
    loading: true,
    item: {},
  }),
  created() {
    this.getItem();
  },
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    itemId() {
      return Number(this.$route.params.item.replace(/^.*-([0-9]+)$/, "$1"));
    },
    images() {
      if (!this.item?.img?.list) return [];

      return this.item.img.list.map(({ path }) => ({
        src: this.files + path + "?resize=1&w=1280",
        thumbnail: this.files + path + "?resize=1&w=400",
      }));
    },
    mobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    getItem() {
      this.loading = true;

      this.$rest(_config.mod)
        .get({ id: this.itemId })
        .then((data) => (this.item = data))
        .then(() => (this.loading = false));
    },
    share() {
      navigator.share({
        url: this.server + this.$route.fullPath,
      });
    },
  },
  watch: {
    $route() {
      this.getItem();
    },
  },
  components: {
    LoadingWidget,
    TitleWidget,
    ProductImagesWidget,
    ButtonWidget,
    RelatedProductsWidget,
  },
};
</script>

<style lang="scss" scoped>
.item {
  .grid-list {
    display: grid;
    gap: 0 36px;
    grid-template-columns: 60% auto;

    @media screen and (max-width: 960px) {
      grid-template-columns: 100%;
    }
  }
}
</style>
