<template>
  <div
    :class="[
      'd-flex text-h4 text-md-h3 text-uppercase font-weight-bold',
      dark ? 'white--text' : 'primary--text',
      className,
    ]"
  >
    <div>
      <div class="font-secondary">
        <slot />
      </div>

      <v-sheet
        class="mt-3 mt-md-4"
        :class="{ 'mx-auto': align === 'center' }"
        :color="dark ? 'white' : 'primary'"
        width="85px"
        height="3px"
      ></v-sheet>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: "left",
      validator: (val) => ["left", "center", "right"].includes(val),
    },
  },
  computed: {
    className() {
      const classNameAlign = {
        left: "text-left justify-start",
        center: "text-center justify-center",
        right: "text-right justify-end",
      };

      return classNameAlign[this.align] || "";
    },
  },
};
</script>
