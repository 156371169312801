<template>
  <v-card class="overflow-hidden position-relative item" rounded="xl" flat>
    <img
      class="d-block item-img"
      :src="files + img + '?resize=1&w=450'"
      :alt="title"
      width="100%"
    />

    <div class="d-flex align-center justify-center item-caption">
      <div>
        <div
          class="
            white--text
            text-center text-h5 text-md-h4 text-uppercase
            font-weight-bold
          "
        >
          <span class="font-secondary"> {{ title }} </span>
        </div>

        <v-sheet
          class="mt-2 mx-auto"
          color="white"
          width="85px"
          height="3px"
        ></v-sheet>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    gallery: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  &-img {
    aspect-ratio: 16 / 9;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
  }

  &-caption {
    background-color: rgba(48, 48, 48, 0.4);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
