<template>
  <v-sheet
    v-if="!loading && item.title && item.img && parsedList.length"
    color="primary"
  >
    <v-container class="py-10 py-md-13 py-lg-16" :fluid="mdAndDown">
      <v-row class="mt-4 mt-md-6" justify="center">
        <v-col cols="12" sm="10" md="12">
          <TitleWidget dark>
            {{ item.title }}
          </TitleWidget>

          <div
            v-if="item.subtitle"
            class="
              white--text
              text-h6 text-md-h5
              font-weight-medium
              mt-4 mt-md-6
            "
          >
            {{ item.subtitle }}
          </div>
        </v-col>

        <v-col cols="12" sm="10" md="6">
          <img
            class="d-block"
            :src="files + item.img + '?resize=1&w=600'"
            :alt="item.title"
            width="100%"
          />
        </v-col>
        <v-col cols="12" md="6">
          <MasonryGrid align="stretch" :column="smOnly ? 3 : 2" :gap="10">
            <template v-for="(item, keyList) in parsedList">
              <div :key="keyList" class="d-flex">
                <v-sheet
                  class="rounded-pill list-left"
                  :color="item[0].category.color"
                  width="6px"
                ></v-sheet>

                <div class="ml-3 d-flex flex-column">
                  <template v-for="{ title } in item">
                    <div :key="title">
                      <div
                        class="
                          white--text
                          text-caption text-sm-body-1
                          font-weight-medium
                        "
                      >
                        {{ title }}
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </MasonryGrid>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import TitleWidget from "../widgets/TitleWidget.vue";
import { MasonryGrid } from "@egjs/vue-grid";
import groupBy from "lodash.groupby";

export default {
  data: () => ({
    loading: true,
  }),
  computed: {
    item() {
      return this.$rest("map").item;
    },
    list() {
      return this.$rest("map-cities").list;
    },
    parsedList() {
      const parsedList = [];
      const groupByCategory = groupBy(this.list, "category.id");

      Object.values(groupByCategory).forEach((item) => {
        parsedList.push(item);
      });

      return parsedList.sort(function (a, b) {
        if (a.length < b.length) return 1;
        else if (a.length == b.length) return 0;

        return -1;
      });
    },
    mdAndDown() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    smOnly() {
      return this.$vuetify.breakpoint.smOnly;
    },
  },
  created() {
    const item = this.$rest("map").get({
      save: (state, data) => (state.item = data),
    });
    const list = this.$rest("map-cities").get();

    Promise.all([item, list]).finally(() => (this.loading = false));
  },
  components: {
    TitleWidget,
    MasonryGrid,
  },
};
</script>

<style lang="scss" scoped>
.grid-list {
  display: grid;
  gap: 20px 0;
  grid-template-columns: 50% 50%;
}

.list-left {
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
}
</style>
