<template>
  <div>
    <LoadingWidget v-if="loading" />

    <transition name="fade">
      <div v-if="!loading && items.length">
        <TitleWidget :align="mobile ? 'center' : 'left'">
          produtos relacionados
        </TitleWidget>

        <div class="mt-6 grid-list">
          <template v-for="(item, keyItems) in items">
            <ProductCard
              :key="keyItems"
              v-if="(mdOnly && keyItems + 1 !== itemsPerPage) || !mdOnly"
              v-bind="item"
            />
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import LoadingWidget from "./LoadingWidget.vue";
import TitleWidget from "./TitleWidget.vue";
import ProductCard from "../cards/ProductCard.vue";
import { formatToURL } from "../../utils/formatToURL";

const _config = {
  mod: "products",
  path: "/produtos",
  itemsPerPage: 4,
};

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    category: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    loading: true,
    items: [],
    itemsPerPage: _config.itemsPerPage,
  }),
  created() {
    this.getList();
  },
  computed: {
    mdOnly() {
      return this.$vuetify.breakpoint.mdOnly;
    },
    mobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    getList() {
      this.loading = true;

      const params = {
        itemsPerPage: _config.itemsPerPage,
        id: this.id,
        category: this.category,
      };

      this.$rest(_config.mod)
        .get({ params })
        .then((data) => {
          this.items = data.map(({ id, img, title }) => ({
            img,
            title,
            to: `${_config.path}/${formatToURL(title)}-${id}`,
          }));
        })
        .then(() => (this.loading = false));
    },
  },
  components: {
    LoadingWidget,
    TitleWidget,
    ProductCard,
  },
};
</script>

<style lang="scss" scoped>
.grid-list {
  display: grid;
  gap: 40px 30px;
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: 1264px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 600px) {
    gap: 12px;
  }
}
</style>
