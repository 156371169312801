import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./plugins/vuex";
import "./plugins/vuex-rest";
import "./plugins/v-hover";
import "./plugins/hooper";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "./styles/main.scss";
import "./styles/ck-content.css";

import { server } from "./config";

Vue.config.productionTip = false;

Vue.mixin({
  data: () => ({
    server,
    files: server,
  }),
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
