<template>
  <v-sheet color="transparent" :width="mobile ? '100%' : '265px'">
    <v-form
      @submit="submit"
      class="position-relative products-search"
      ref="productSearch"
    >
      <v-text-field
        v-model="q"
        v-bind="fieldProps"
        class="search-input"
        background-color="secondary lighten-3"
        hide-details
        width="265px"
        :height="mobile ? '42px' : '54px'"
        placeholder="Buscar"
      ></v-text-field>

      <v-btn
        class="rounded-sm submit-btn"
        color="transparent"
        type="submit"
        :width="mobile ? '42px' : '54px'"
        :height="mobile ? '42px' : '54px'"
        depressed
        fab
      >
        <v-icon color="secondary" size="18"> fa-search </v-icon>
      </v-btn>
    </v-form>
  </v-sheet>
</template>

<script>
import { mixin } from "./fields/mixin";

export default {
  data: () => ({
    q: "",
  }),
  mixins: [mixin],
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    submit(event) {
      event.preventDefault();
      const form = this.$refs.productSearch;

      if (form.validate()) {
        const search = new URLSearchParams();
        search.set("q", this.q);
        this.$router.push("?" + search.toString());
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.products-search {
  .search-input::v-deep {
    fieldset {
      border: 0 !important;
    }
  }

  .submit-btn {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
