<template>
  <div>
    <LoadingWidget v-if="!items.length && loading" />

    <transition name="fade">
      <v-sheet v-if="items.length" color="#f5f5f5">
        <v-container class="py-10 py-md-13 py-lg-16">
          <TitleWidget :align="mobile ? 'center' : 'left'">
            CATEGORIAS
          </TitleWidget>

          <div class="mt-8 grid-list">
            <template v-for="(item, keyItems) in items">
              <ProductCategoryCard :key="keyItems" v-bind="item" />
            </template>
          </div>
        </v-container>
      </v-sheet>
    </transition>
  </div>
</template>

<script>
import LoadingWidget from "../widgets/LoadingWidget.vue";
import TitleWidget from "../widgets/TitleWidget.vue";
import ProductCategoryCard from "../cards/ProductCategoryCard.vue";
import { formatToURL } from "../../utils/formatToURL";

const _config = {
  mod: "productsCategories",
  path: "/produtos",
};

export default {
  data: () => ({
    loading: true,
  }),
  mounted() {
    this.$rest(_config.mod)
      .get()
      .then(() => (this.loading = false));
  },
  computed: {
    items() {
      return this.$rest(_config.mod).list.map(({ id, img, title }) => ({
        img,
        title,
        to: `${_config.path}?categoria=${formatToURL(title)}-${id}`,
      }));
    },
    mobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  components: {
    LoadingWidget,
    TitleWidget,
    ProductCategoryCard,
  },
};
</script>

<style lang="scss" scoped>
.grid-list {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 600px) {
    gap: 16px;
  }
}
</style>
