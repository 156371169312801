<template>
  <div v-if="images.length">
    <img
      v-if="featuredImage || images[0].src"
      @click="index = featuredIndex || 0"
      :class="[
        'd-block cursor-pointer item-img',
        mobile ? 'rounded-sm' : 'rounded-xl',
      ]"
      :src="featuredImage || images[0].src"
      width="100%"
    />

    <div v-if="images.length > 1" class="mt-4">
      <div class="position-relative">
        <div class="px-4">
          <hooper-container
            class="property-slides-images"
            style="height: auto"
            ref="carouselImages"
            :settings="hooperSettings"
            :mouseDrag="images.length > 1"
            :touchDrag="images.length > 1"
            @slide="updateCarousel"
          >
            <hooper-slide
              class="px-2 item"
              v-for="(item, keyImages) in images"
              :key="keyImages"
            >
              <div
                class="position-relative slides-img"
                :class="{
                  'slides-img-featured':
                    item.src === featuredImage ||
                    (!featuredImage && keyImages === 0),
                }"
              >
                <img
                  @click="
                    featuredImage = item.src;
                    featuredIndex = keyImages;
                    slideTo(keyImages);
                  "
                  :class="[
                    'd-block cursor-pointer',
                    mobile ? 'rounded-sm' : 'rounded-lg',
                  ]"
                  :src="item.thumbnail"
                  width="100%"
                />
              </div>
            </hooper-slide>
          </hooper-container>
        </div>

        <div
          v-if="images.length > 1"
          class="d-flex justify-space-between slides-nav"
        >
          <a
            class="
              d-flex
              align-center
              justify-center
              elevation-2
              cursor-pointer
              slides-nav-item
            "
            @click.prevent="slidePrev"
          >
            <v-icon color="primary" :size="18"> fa-angle-left </v-icon>
          </a>
          <a
            class="
              d-flex
              align-center
              justify-center
              elevation-2
              cursor-pointer
              slides-nav-item
            "
            @click.prevent="slideNext"
          >
            <v-icon color="primary" :size="18"> fa-angle-right </v-icon>
          </a>
        </div>
      </div>
    </div>

    <Tinybox :images="images" v-model="index" />
  </div>
</template>

<script>
import Tinybox from "vue-tinybox";

export default {
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    index: null,
    featuredImage: null,
    featuredIndex: null,
    hooperSettings: {
      itemsToShow: 2,
      itemsToSlide: 1,
      playSpeed: 3000,
      autoPlay: true,
      hoverPause: true,
      wheelControl: false,
      infiniteScroll: true,
      centerMode: true,
      breakpoints: {
        600: {
          itemsToShow: 4,
        },
        960: {
          itemsToShow: 5,
        },
      },
    },
  }),
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    slidePrev() {
      this.$refs.carouselImages.slidePrev();
    },
    slideNext() {
      this.$refs.carouselImages.slideNext();
    },
    slideTo(index) {
      this.$refs.carouselImages.slideTo(index);
    },
    updateCarousel(payload) {
      this.featuredIndex =
        payload.currentSlide === -1
          ? this.images.length - 1
          : payload.currentSlide < this.images.length
          ? payload.currentSlide
          : 0;
      this.featuredImage = this.images[this.featuredIndex].src;
    },
  },
  watch: {
    carouselData() {
      this.$refs.carouselImages.slideTo(this.carouselData);
    },
    images() {
      this.$refs.carouselImages.restart();
    },
  },
  components: {
    Tinybox,
  },
};
</script>

<style lang="scss" scoped>
.item-img {
  aspect-ratio: 16 / 9;
  -o-object-fit: cover;
  object-fit: cover;

  @media screen and (max-width: 600px) {
    aspect-ratio: 4 / 3;
  }
}

.slides-img {
  &:before {
    background-color: rgba(255, 255, 255, 0.6);
    bottom: 0;
    content: "";
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
  }

  &-featured {
    &:before {
      background-color: transparent;
    }
  }

  img {
    aspect-ratio: 16 / 9;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.property-slides-images::v-deep {
  ul,
  ol {
    padding-left: 0 !important;
  }
}

.slides-nav {
  height: 0;
  left: 0;
  margin-top: -15px;
  position: absolute;
  top: 50%;
  width: 100%;

  &-item {
    background-color: #ffffff;
    border-radius: 50%;
    height: 30px;
    width: 30px;
  }
}
</style>
