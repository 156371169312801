<template>
  <div>
    <LoadingWidget v-if="!items.length && loading" />

    <transition name="fade">
      <v-container v-if="items.length" class="py-10 py-md-13 py-lg-16">
        <TitleWidget :align="xsOnly ? 'center' : 'left'">
          PRODUTOS EM DESTAQUE
        </TitleWidget>

        <div class="mx-n1 mx-sm-n3">
          <CarouselWidget
            class="mt-8 mt-md-12 items"
            :items="items"
            :itemsToShow="itemsToShow"
            dots
            :nav="!mobile"
          >
            <template #item="{ item }">
              <div class="px-1 px-sm-3">
                <ProductCard v-bind="item" limitTitle />
              </div>
            </template>
          </CarouselWidget>
        </div>
      </v-container>
    </transition>
  </div>
</template>

<script>
import LoadingWidget from "../widgets/LoadingWidget.vue";
import TitleWidget from "../widgets/TitleWidget.vue";
import CarouselWidget from "../widgets/CarouselWidget.vue";
import ProductCard from "../cards/ProductCard.vue";
import { formatToURL } from "../../utils/formatToURL";

const _config = {
  mod: "products",
  path: "/produtos",
};

export default {
  data: () => ({
    loading: true,
    itemsToShow: {
      xs: 2,
      sm: 3,
      md: 3,
      lg: 4,
      xl: 4,
    },
  }),
  mounted() {
    const params = {
      featured: 1,
    };

    this.$rest(_config.mod)
      .get({ params })
      .then(() => (this.loading = false));
  },
  computed: {
    items() {
      return this.$rest(_config.mod).list.map(({ id, img, title }) => ({
        img,
        title,
        to: `${_config.path}/${formatToURL(title)}-${id}`,
      }));
    },
    mobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    getList() {
      this.loading = true;
    },
  },
  components: {
    LoadingWidget,
    TitleWidget,
    CarouselWidget,
    ProductCard,
  },
};
</script>
