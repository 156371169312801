<template>
  <div>
    <v-container class="py-10 py-md-13 py-lg-16">
      <TitleWidget align="center">Fale Conosco</TitleWidget>

      <v-row class="mt-6" justify="center">
        <v-col cols="12" md="7" lg="6">
          <ContactForm
            :action="files + formAction"
            response-to="/fale-conosco/obrigado"
          />
        </v-col>
      </v-row>
    </v-container>

    <div
      v-if="informations.map"
      class="grey lighten-2 map"
      v-html="informations.map"
    ></div>
  </div>
</template>

<script>
import TitleWidget from "../components/widgets/TitleWidget.vue";
import ContactForm from "../components/forms/ContactForm.vue";

export default {
  data: () => ({
    formAction: "/rest/email/contact-us",
  }),
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
  },
  components: {
    TitleWidget,
    ContactForm,
  },
};
</script>

<style lang="scss" scoped>
.map::v-deep {
  aspect-ratio: 1920 / 450;
  min-height: 400px;
  width: 100%;

  iframe {
    display: block;
    height: 100% !important;
    width: 100% !important;
  }
}
</style>
