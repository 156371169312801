<template>
  <div>
    <SlideShowSection />
    <ProductsFeaturedSection />
    <ProductsCategoriesSection />
    <JobsSection />
    <MapSection />
  </div>
</template>

<script>
import SlideShowSection from "../components/sections/SlideShowSection.vue";
import ProductsFeaturedSection from "../components/sections/ProductsFeaturedSection.vue";
import ProductsCategoriesSection from "../components/sections/ProductsCategoriesSection.vue";
import JobsSection from "../components/sections/JobsSection.vue";
import MapSection from "../components/sections/MapSection.vue";

export default {
  components: {
    SlideShowSection,
    ProductsFeaturedSection,
    ProductsCategoriesSection,
    JobsSection,
    MapSection,
  },
};
</script>
