<template>
  <div>
    <LoadingWidget v-if="!item.title && loading" />

    <transition name="fade">
      <v-sheet class="item" color="#ddd6cc">
        <iframe
          id="about-pdf"
          class="d-block about-pdf"
          src="https://gamma.app/embed/jnbadxgfzlotfl1"
          frameborder="0"
          width="100%"
          title="Vitória Perfilados: Excelência em Aço e Telhas Metálicas"
        ></iframe>

        <v-container v-if="item.video" class="pt-0 pb-10 pb-md-13 pb-lg-16">
          <div class="black mt-8 mt-md-10 video">
            <iframe
              class="d-block"
              :src="`https://www.youtube.com/embed/${getYoutubeCode(
                item.video
              )}`"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </v-container>
      </v-sheet>
    </transition>
  </div>
</template>

<script>
import LoadingWidget from "../components/widgets/LoadingWidget.vue";
import { getYoutubeCode } from "../utils/getYoutubeCode";

const _config = {
  mod: "about",
};

export default {
  data: () => ({
    loading: true,
    index: null,
    showPdf: false,
  }),
  created() {
    this.$rest(_config.mod)
      .get({
        save: (state, data) => (state.item = data),
      })
      .then(() => (this.loading = false));
  },
  mounted() {
    setTimeout(() => {
      document.getElementById("about-pdf").height =
        window.parent.document.getElementById(
          "about-pdf"
        ).contentWindow.document.body.height;
    }, 5000);
  },
  computed: {
    item() {
      return this.$rest(_config.mod).item;
    },
    image() {
      return this.item?.img?.featured || "";
    },
    images() {
      const images = [];
      if (this.item.img && this.item.img.list && this.item.img.list.length)
        this.item.img.list.map(({ path }) => {
          if (path != this.image)
            images.push({
              src: this.files + path + "?resize=1&w=1280",
              thumbnail: this.files + path + "?resize=1&w=400",
            });
        });

      return images;
    },
    mobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    getYoutubeCode,
  },
  components: {
    LoadingWidget,
  },
};
</script>

<style lang="scss" scoped>
.item {
  &-img {
    max-height: 70vh;
    -o-object-fit: contain;
    object-fit: contain;
  }
}

.images-grid-list {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: 960px) {
    gap: 5px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  img {
    aspect-ratio: 16 / 12;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.about-pdf {
  border: 0;
  height: 100vh;
  width: 100%;
}
</style>
