<template>
  <div>
    <LoadingWidget v-if="!items.length && loading" />

    <transition name="fade">
      <v-sheet v-if="items.length" color="secondary lighten-3">
        <v-container class="py-10 py-md-13 py-lg-16">
          <TitleWidget :align="mobile ? 'center' : 'left'">
            NOSSOS TRABALHOS
          </TitleWidget>

          <div class="mx-n1 mx-sm-n3">
            <CarouselWidget
              class="mt-8 mt-md-12 items"
              :items="items"
              :itemsToShow="itemsToShow"
              dots
              :nav="!mdAndDown"
            >
              <template #item="{ item }">
                <div
                  @click="
                    gallery = item.gallery;
                    index = 0;
                  "
                  v-ripple
                  class="px-1 px-sm-3 cursor-pointer"
                >
                  <JobCard v-bind="item" />
                </div>
              </template>
            </CarouselWidget>
          </div>
        </v-container>
      </v-sheet>
    </transition>

    <Tinybox :images="gallery" v-model="index" />
  </div>
</template>

<script>
import LoadingWidget from "../widgets/LoadingWidget.vue";
import TitleWidget from "../widgets/TitleWidget.vue";
import CarouselWidget from "../widgets/CarouselWidget.vue";
import JobCard from "../cards/JobCard.vue";
import Tinybox from "vue-tinybox";

const _config = {
  mod: "jobs",
};

export default {
  data: () => ({
    loading: true,
    itemsToShow: {
      xs: 1.15,
      sm: 2,
      md: 2,
      lg: 2,
      xl: 2,
    },
    index: null,
    gallery: [],
  }),
  mounted() {
    const params = {
      featured: 1,
    };

    this.$rest(_config.mod)
      .get({ params })
      .then(() => (this.loading = false));
  },
  computed: {
    items() {
      return this.$rest(_config.mod).list.map(({ img, title }) => ({
        img: img?.featured,
        title,
        gallery: [
          ...(img?.featured
            ? [
                {
                  src: this.files + img.featured + "?resize=1&w=1280",
                  thumbnail: this.files + img.featured + "?resize=1&w=200",
                },
              ]
            : []),
          ...img?.list.map(({ path }) => {
            return {
              src: this.files + path + "?resize=1&w=1280",
              thumbnail: this.files + path + "?resize=1&w=200",
            };
          }),
        ],
      }));
    },
    mdAndDown() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    mobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  components: {
    LoadingWidget,
    TitleWidget,
    CarouselWidget,
    JobCard,
    Tinybox,
  },
};
</script>
